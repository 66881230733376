jQuery.noConflict();
jQuery(function ($) {
	const headerContent = $('#masthead > .header-content');
	const headerContentHeight = headerContent.outerHeight();
	const navigation = $('#masthead > nav.navigation');
	const navigationHeight = navigation.outerHeight();
	// $('p#breadcrumbs').css('top', navigationHeight + 'px');
	$('#primary .page-header').css('top', navigationHeight + 'px');
	$('aside > ul.product-families').css('top', navigationHeight + 'px');
	$('.blog #primary .archive-header').css('top', navigationHeight + 'px');
	$('.single-post #primary .post-header').css('top', navigationHeight + 'px');
	$(window).scroll(function () {
		if ($(this).scrollTop() > headerContentHeight) {
			$(navigation).addClass('sticky');
			$('body').css('padding-top', navigationHeight + 'px');
			// $('p#breadcrumbs').addClass('sticky');
			$('aside').addClass('sticky');
		} else {
			// $('p#breadcrumbs').css('top', 'auto');
			$('aside > ul.product-families').css('padding-top', '0');
			$(navigation).removeClass('sticky');
			// $('p#breadcrumbs').removeClass('sticky');
			$('aside').removeClass('sticky');
			$('body').css('padding-top', 0);
		}
	});
});
