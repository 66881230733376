jQuery(document).ready(function ($) {
	if ($('body').hasClass('single-product')) {
		const parent = $('.product-content').data('term-parent');
		const term = $('.product-content').data('term');
		$.each(parent, function (index, id) {
			$(
				'.product-families li a span[data-term-parent="' + id + '"]'
			).addClass('current');
		});
		$.each(term, function (index, id) {
			$('nav.term-children li.child[data-term="' + id + '"]').addClass(
				'current'
			);
		});
	} else if ($('body').hasClass('tax-product_cat')) {
		const parent = $('.products-list').data('term-parent');
		const term = $('.products-list').data('term');
		if (parent === 0) {
			$(
				'.product-families li a span[data-term-parent="' + term + '"]'
			).addClass('current');
		} else {
			$(
				'.product-families li a span[data-term-parent="' + parent + '"]'
			).addClass('current');
			$('nav.term-children li.child[data-term="' + term + '"]').addClass(
				'current'
			);
		}
	}
});
