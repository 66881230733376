jQuery(document).ready(function ($) {
	const button = $('.add-to-cart a.button');
	$(button).click(function () {
		const cod = $(this).data('cod');
		// const mod = $(this).data('mod');
		setTimeout(function () {
			const inputCod = $('input[name="product_cod"]');
			const elInputCod = $('input[name="form_fields[product_cod]"]');
			// const inputMod = $('textarea[name="product_mod"]');
			$(inputCod).val('SKU: ' + cod);
			$(elInputCod).val('SKU: ' + cod);
			// $(inputMod).val('MOD: ' + mod);
		}, 700);
	});
});
