jQuery(document).ready(function ($) {
	$('li.parent > svg').click(function () {
		$('li.parent > a').parent().removeClass('opened');
		if ($(this).parent().hasClass('open')) {
			$(this).parent().removeClass('open');
		} else {
			$('li.parent').removeClass('open');
			$(this).parent().addClass('open');
		}
	});

	if ($('body').hasClass('single-product')) {
		const parent = $('.product-content').data('term-parent');
		const term = $('.product-content').data('term');
		$.each(parent, function (index, id) {
			$('aside li.parent[data-term-parent="' + id + '"]').addClass(
				'opened'
			);
		});
		$.each(term, function (index, id) {
			$('aside li.child[data-term="' + id + '"]').addClass('current');
		});
	} else if ($('body').hasClass('tax-product_cat')) {
		const parent = $('.products-list').data('term-parent');
		const term = $('.products-list').data('term');
		if (parent === 0) {
			$('aside li.parent[data-term-parent="' + term + '"]').addClass(
				'opened'
			);
			$('aside li.child[data-term="' + term + '"]').addClass('current');
		} else {
			$('aside li.parent[data-term-parent="' + parent + '"]').addClass(
				'opened'
			);
			$('aside li.child[data-term="' + term + '"]').addClass('current');
		}
	}

	if (
		$('body').hasClass('single-product') ||
		$('body').hasClass('tax-product_cat')
	) {
		const ele = $('.child.current');
		const eleParent = $(ele).data('parent');
		const pos = ele.position().top - ele.height();
		ele.closest('ul.childs')
			.data('parent', eleParent)
			.animate({ scrollTop: pos }, 'fast');
	}
});
