jQuery.noConflict();
jQuery(function ($) {
	$('td.stock-status span.light').on('mouseover touchstart', function () {
		if ($('td.stock-status').hasClass('show')) {
			$('td.stock-status').removeClass('show');
		} else {
			$(this).parents('.stock-status').addClass('show');
		}
	});

	$('td.stock-status span.light').on('mouseleave touchend', function () {
		$(this).parents('.stock-status').removeClass('show');
	});

	$('td.info i.show-tooltip').click(function (e) {
		e.stopPropagation();
		if ($('td.info').hasClass('show')) {
			$('td.info').removeClass('show');
		}
		$(this).parents('.info').toggleClass('show');
	});
	$('.tooltip i.fa-xmark').click(function () {
		if ($('td.info').hasClass('show')) {
			$('td.info').removeClass('show');
		}
	});
});
